import React, { useState, useEffect } from 'react';
import { db, collection, addDoc, auth, serverTimestamp, query, where, getDocs } from '../firebase'; // Mengimpor query, where, dan getDocs
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, IconButton, InputAdornment } from '@mui/material';
import PasteIcon from '@mui/icons-material/ContentPaste';

const ShortenUrl = () => {
  const [originalUrl, setOriginalUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [customUrl, setCustomUrl] = useState('');
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        navigate('/dashboard'); // Redirect to dashboard if the user is logged in
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [navigate]); // Add `navigate` to the dependency array

  const formatUrl = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  const generateShortUrl = async () => {
    const formattedOriginalUrl = formatUrl(originalUrl);
    const urlId = customUrl || Math.random().toString(36).substring(2, 8);
    const urlRef = collection(db, 'urls');
    const existingUrl = await getDocs(query(urlRef, where('shortUrl', '==', urlId))); // Menggunakan query, where, dan getDocs

    if (!existingUrl.empty) {
      alert('Custom URL is already in use. Please choose another one.');
      return;
    }

    await addDoc(urlRef, {
      originalUrl: formattedOriginalUrl,
      shortUrl: urlId,
      userId: user ? user.uid : null,
      visits: 0,
      createdAt: serverTimestamp(),
    });
    setShortUrl(urlId);
  };

  const handlePaste = (e) => {
    navigator.clipboard.readText().then(text => setOriginalUrl(text));
  };

  return (
    <Box sx={{ maxWidth: 500, mx: 'auto', my: 5, p: 3, backgroundColor: 'white', borderRadius: 1, boxShadow: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        URL Shortener
      </Typography>
      <TextField 
        fullWidth 
        margin="normal"
        label="Enter original URL"
        value={originalUrl} 
        onChange={(e) => setOriginalUrl(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handlePaste}>
                <PasteIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField 
        fullWidth 
        margin="normal"
        label="Custom short URL (optional)"
        value={customUrl} 
        onChange={(e) => setCustomUrl(e.target.value)} 
      />
      <Button 
        fullWidth 
        variant="contained" 
        color="primary"
        onClick={generateShortUrl}
      >
        Shorten
      </Button>
      {shortUrl && (
        <Typography variant="body1" component="p" sx={{ mt: 2 }}>
          Shortened URL: {window.location.origin}/{shortUrl}
          <Button variant="outlined" color="primary" onClick={() => navigator.clipboard.writeText(`${window.location.origin}/${shortUrl}`)}>
            Copy
          </Button>
        </Typography>
      )}
      
      {!user && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" component="h2" gutterBottom>Please Log In or Register</Typography>
          <Button 
            fullWidth 
            variant="contained" 
            color="secondary"
            sx={{ mb: 1 }}
            onClick={() => navigate('/login')}
          >
            Login
          </Button>
          <Button 
            fullWidth 
            variant="contained" 
            color="success"
            onClick={() => navigate('/register')}
          >
            Register
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ShortenUrl;
