import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FileCopy } from '@mui/icons-material';
import { db, doc, updateDoc, deleteDoc, collection, query, where, getDocs } from '../firebase';
import { generateQRCode } from './qrcodeGenerator';

const UrlTable = ({ urls, fetchUrls, user }) => {
  const [newShortUrl, setNewShortUrl] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteUrlId, setDeleteUrlId] = useState(null);
  const [openGenerateDialog, setOpenGenerateDialog] = useState(false);
  const [generateUrlId, setGenerateUrlId] = useState(null);

  const handleEditUrl = async (id) => {
    if (newShortUrl) {
      const urlRef = doc(db, 'urls', id);
      const existingUrl = await getDocs(
        query(collection(db, 'urls'), where('shortUrl', '==', newShortUrl))
      );

      if (!existingUrl.empty) {
        alert('Custom URL is already in use. Please choose another one.');
        return;
      }

      await updateDoc(urlRef, { shortUrl: newShortUrl });
      fetchUrls(user.uid);
      setNewShortUrl('');
    }
  };

  const handleDeleteUrl = async () => {
    if (deleteUrlId) {
      const urlRef = doc(db, 'urls', deleteUrlId);
      await deleteDoc(urlRef);
      fetchUrls(user.uid);
      setDeleteUrlId(null);
      setOpenDeleteDialog(false);
    }
  };

  const handleGenerateQRCode = async () => {
    if (generateUrlId) {
      const url = urls.find((url) => url.id === generateUrlId);
      if (url) {
        try {
          const qrCodeLink = await generateQRCode(url.originalUrl, url.shortUrl);
          await updateDoc(doc(db, 'urls', generateUrlId), { qrCodeUrl: qrCodeLink });
          fetchUrls(user.uid);
        } catch (error) {
          console.error('Failed to generate QR code:)', error);
        }
      }
      setGenerateUrlId(null);
      setOpenGenerateDialog(false);
    }
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const openDeleteConfirmation = (id) => {
    setDeleteUrlId(id);
    setOpenDeleteDialog(true);
  };

  const openGenerateConfirmation = (id) => {
    setGenerateUrlId(id);
    setOpenGenerateDialog(true);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Original URL</TableCell>
            <TableCell>Short URL</TableCell>
            <TableCell>Traffic</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>Copy URL</TableCell>
            <TableCell>Generate</TableCell>
            <TableCell>QR Code</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {urls
            .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
            .map((url) => (
              <TableRow key={url.id}>
                <TableCell>
                  {new Date(url.createdAt.toMillis()).toLocaleString()}
                </TableCell>
                <TableCell>{url.originalUrl}</TableCell>
                <TableCell>{url.shortUrl}</TableCell>
                <TableCell>{url.visits}</TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    label="New Short URL"
                    value={newShortUrl}
                    onChange={(e) => setNewShortUrl(e.target.value)}
                    sx={{ minWidth: '150px' }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleEditUrl(url.id)}
                    sx={{ marginTop: '8px' }}
                  >
                    Edit
                  </Button>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() =>
                      handleCopyToClipboard(
                        `${window.location.origin}/${url.shortUrl}`
                      )
                    }
                  >
                    <FileCopy />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => openGenerateConfirmation(url.id)}
                  >
                    Generate QR Code
                  </Button>
                </TableCell>
                <TableCell>
                  {url.qrCodeUrl && (
                    <img
                      src={url.qrCodeUrl}
                      alt="QR Code"
                      style={{ width: '50px', cursor: 'pointer' }}
                      onClick={() => window.open(url.qrCodeUrl, '_blank')}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => openDeleteConfirmation(url.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      {/* Dialog Konfirmasi Delete */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this URL?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteUrl} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Konfirmasi Generate QR Code */}
      <Dialog
        open={openGenerateDialog}
        onClose={() => setOpenGenerateDialog(false)}
      >
        <DialogTitle>Confirm Generate QR Code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to regenerate the QR code for this URL?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenGenerateDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleGenerateQRCode} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default UrlTable;