import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';

const AuthChecker = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(currentUser => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Menampilkan "Loading..." selama proses pengecekan autentikasi
  if (user === null) {
    return <p>Loading...</p>;
  }

  // Jika user ada, menampilkan children (komponen yang dilindungi)
  return (
    <>
      {user ? children : <p>Unauthorized</p>}
    </>
  );
};

export default AuthChecker;
