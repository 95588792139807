import React, { useState } from 'react';
import { db, collection, addDoc, auth, serverTimestamp, query, where, getDocs } from '../firebase';
import { TextField, Button, Box, Typography, IconButton, InputAdornment } from '@mui/material';
import PasteIcon from '@mui/icons-material/ContentPaste';

const UrlInputForm = ({ fetchUrls }) => {
  const [originalUrl, setOriginalUrl] = useState('');
  const [customUrl, setCustomUrl] = useState('');
  const [user, setUser] = useState(null);

  useState(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const generateShortUrl = async () => {
    let formattedUrl = originalUrl;

    if (!/^https?:\/\//i.test(formattedUrl)) {
      formattedUrl = `https://${formattedUrl}`;
    }

    const urlId = customUrl || Math.random().toString(36).substring(2, 8);
    const urlRef = collection(db, 'urls');
    const existingUrl = await getDocs(query(urlRef, where('shortUrl', '==', urlId)));

    if (!existingUrl.empty) {
      alert('Custom URL is already in use. Please choose another one.');
      return;
    }

    await addDoc(urlRef, {
      originalUrl: formattedUrl,
      shortUrl: urlId,
      userId: user ? user.uid : null,
      visits: 0,
      createdAt: serverTimestamp(),
    });

    setOriginalUrl('');
    setCustomUrl('');
    fetchUrls(user.uid);
  };

  const handlePaste = () => {
    navigator.clipboard.readText().then(text => setOriginalUrl(text));
  };

  return (
    <Box sx={{ maxWidth: 500, mx: 'auto', my: 5, p: 3, backgroundColor: 'white', borderRadius: 1, boxShadow: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>URL Shortener</Typography>
      <TextField
        fullWidth
        margin="normal"
        label="Enter original URL"
        value={originalUrl}
        onChange={(e) => setOriginalUrl(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handlePaste}>
                <PasteIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Custom short URL (optional)"
        value={customUrl}
        onChange={(e) => setCustomUrl(e.target.value)}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={generateShortUrl}
      >
        Shorten
      </Button>
    </Box>
  );
};

export default UrlInputForm;
