import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, sendPasswordResetEmail, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, updateDoc, doc, deleteDoc, addDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCLIQwORB8LMxqydbiQiwIs7QB5TCE9SLk",
    authDomain: "taz-my.firebaseapp.com",
    projectId: "taz-my",
    storageBucket: "taz-my.appspot.com",
    messagingSenderId: "968634962633",
    appId: "1:968634962633:web:585198ec62820d797bf318",
    measurementId: "G-BX492KEKFN"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage
const googleProvider = new GoogleAuthProvider();

export {
    auth,
    googleProvider,
    db,
    storage, // Export storage
    collection,
    query,
    where,
    getDocs,
    updateDoc,
    doc,
    deleteDoc,
    addDoc,
    serverTimestamp,
    signInWithPopup,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    createUserWithEmailAndPassword
};
