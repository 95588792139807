import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import QRCode from 'qrcode';

const storage = getStorage();

export const generateQRCode = async (url, fileName) => {
  try {
    // Generate QR code sebagai data URL
    const qrCodeDataUrl = await QRCode.toDataURL(url);

    // Konversi data URL menjadi Blob
    const response = await fetch(qrCodeDataUrl);
    const blob = await response.blob();

    // Upload Blob ke Firebase Storage
    const qrCodeRef = ref(storage, `qrcodes/${fileName}.png`);
    await uploadBytes(qrCodeRef, blob);

    // Dapatkan URL unduhan dari QR code yang diupload
    const downloadUrl = await getDownloadURL(qrCodeRef);

    return downloadUrl;
  } catch (error) {
    console.error('Error generating QR code', error);
    throw new Error('Error generating QR code');
  }
};
