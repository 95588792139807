import React, { useEffect, useState } from 'react';
import { auth, db, collection, query, where, getDocs } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Button } from '@mui/material';
import UrlInputForm from './UrlInputForm';
import UrlTable from './UrlTable';

const Dashboard = () => {
  const [urls, setUrls] = useState([]);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        fetchUrls(user.uid);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUrls = async (userId) => {
    const q = query(collection(db, 'urls'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const urlsData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setUrls(urlsData);
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>
        <Typography variant="h4" gutterBottom>Dashboard</Typography>
        <UrlInputForm fetchUrls={fetchUrls} />
        <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={() => auth.signOut()}>Logout</Button>
        <UrlTable urls={urls} fetchUrls={fetchUrls} user={user} />
      </Box>
    </Container>
  );
};

export default Dashboard;
